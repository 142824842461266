<div class="wri-modifier-groups-container" data-testid="mod-groups-container">
  <div class="wri-mod-wrapper">

    <ng-container
      *ngFor="
        let modifierGroup of modifierGroups
          | onlyModifiersOfType : 'standard' : 'flavor' : 'multiple-quantity';
        index as i
      "
    >
      <hr
        *ngIf="i > 0"
        data-testid="mod-group-divider"
        class="wri-divider"
        aria-hidden="true"
      />
      <!-- Flavor Mod Groups -->
      <wri-flavor-mod-group
        *ngIf="modifierGroup.type === 'flavor'"
        [flavorModGroup]="modifierGroup"
        [isModGroupFirstAndRequired]="
          isModGroupFirstAndRequired(i, modifierGroup)
        "
        [selectedLineItem]="selectedLineItem"
        (valuesChanged)="handleModifierGroupData($event, modifierGroup)"
        [startPDPValidations]="startPDPValidations"
        (allSelectionsValid)="failedModifierGroupsEmit($event, modifierGroup)"
        (resetPDPValidationsEmit)="resetPDPValidations()"
      >
      </wri-flavor-mod-group>

      <!-- Standard Mod Groups -->
      <wri-mod-group
        *ngIf="modifierGroup.type === 'standard'"
        [modifierGroup]="modifierGroup"
        (valuesChanged)="handleModifierGroupData($event, modifierGroup)"
        [isModGroupFirstAndRequired]="
          isModGroupFirstAndRequired(i, modifierGroup)
        "
        [selectedLineItem]="selectedLineItem"
        [startPDPValidations]="startPDPValidations"
        (allSelectionsValid)="failedModifierGroupsEmit($event, modifierGroup)"
        (resetPDPValidationsEmit)="resetPDPValidations()"
      >
      </wri-mod-group>

      <wri-mod-group
        *ngIf="modifierGroup.type === 'multiple-quantity'"
        [modifierGroup]="modifierGroup"
        (valuesChanged)="handleModifierGroupData($event, modifierGroup)"
        [isModGroupFirstAndRequired]="
          isModGroupFirstAndRequired(i, modifierGroup)
        "
        [selectedLineItem]="selectedLineItem"
        [startPDPValidations]="startPDPValidations"
        (allSelectionsValid)="failedModifierGroupsEmit($event, modifierGroup)"
        (resetPDPValidationsEmit)="resetPDPValidations()"
      >
      </wri-mod-group>
    </ng-container>
    <!-- Special Request Mod Groups -->
    <ng-container
      *ngIf="
      (modifierGroups | onlyModifiersOfType : 'special-request').length > 0
      "
    >
      <wri-special-requests
        [modifierGroups]="modifierGroups | onlyModifiersOfType : 'special-request'"
        (valuesChanged)="handleSpecialRequestsModifierGroupsData($event)"
        [selectedLineItem]="selectedLineItem"
      ></wri-special-requests>
    </ng-container>
  </div>

  <!-- Add on Mod Groups -->
  <div
    [ngClass]="filteredAddOnModifiers().length > 0 ? '' : 'remove-add-on'"
    *ngIf="filteredAddOnModifiers().length > 0">
    <wri-add-on-items
      [modifierGroups]="filteredAddOnModifiers() | sort:'asc':'sortOrder'"
      (valuesChanged)="handleAddonModifierGroupsData($event)"
      [selectedLineItem]="selectedLineItem"
      [itemQuantity]="itemQuantity"
    ></wri-add-on-items>
  </div>

</div>
