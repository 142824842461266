/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { find, findIndex } from 'lodash';
import { Subscription } from 'rxjs';

import { CartItem } from '../../../../ecomm/types/cart.types';
import { MenuModifier, MenuModifierGroup, MenuModifierGroupItem } from '../../../../ecomm/types/store-info.types';
import {
  FlavorModifierGroupElement,
  FlavorSpiceHeading,
  FlavorSpiceHeadingColors,
  HeatScalePipe,
  Modifiers
} from '../../../common';
import { FlavorModalComponent } from '../flavor-modal/flavor-modal.component';

export type FlavorGroupFormData = {
  modifiers: Modifiers[] | null;
};

@Component({
  selector: 'wri-flavor-mod-group',
  templateUrl: './flavor-mod-group.component.html',
  styleUrls: ['./flavor-mod-group.component.scss'],
})
export class FlavorModGroupComponent implements OnInit, OnDestroy {
  @Input() flavorModGroup!: MenuModifierGroup;
  @Input() isModGroupFirstAndRequired!: boolean;
  @Input() public selectedLineItem!: CartItem;
  @Input() public startPDPValidations = false;
  @Output()
  resetPDPValidationsEmit = new EventEmitter();
  @Output()
  allSelectionsValid = new EventEmitter<boolean | null>();

  readonly flavorSpiceHeading = FlavorSpiceHeading;
  readonly flavorSpiceHeadingColors = FlavorSpiceHeadingColors;
  groupModifiers: Map<string, MenuModifier[]> = new Map<
    string,
    MenuModifier[]
  >();
  hotGroup: Map<string, MenuModifier[]> = new Map<string, MenuModifier[]>();
  mediumGroup: Map<string, MenuModifier[]> = new Map<string, MenuModifier[]>();
  mildGroup: Map<string, MenuModifier[]> = new Map<string, MenuModifier[]>();
  limitedGroup: Map<string, MenuModifier[]> = new Map<string, MenuModifier[]>();
  @Input() initialValues: Partial<FlavorGroupFormData> = {};
  @Output()
  valuesChanged = new EventEmitter<FlavorGroupFormData>();
  flavorSelectionForm: FormGroup = new FormGroup({});
  public flavorGroupFormData: FlavorGroupFormData | undefined;
  public systemDefined = 0;
  public userDefined = 0;
  @ViewChild(FlavorModalComponent)
  private flavorModal!: FlavorModalComponent;
  private heatScaleTransform = new HeatScalePipe();
  /**
   * @ignore
   */
  private subscription = new Subscription();

  /**
   * @ignore
   */
  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.buildFlavorModifiers();
    this.flavorSelectionForm = this.createForm();
    this.handleFormUpdate(this.flavorSelectionForm.value);
    this.subscription.add(
      this.flavorSelectionForm.valueChanges.subscribe((values) => {
        return this.handleFormUpdate(values);
      })
    );
  }

  @HostListener('window:scroll')
  checkScroll() {
    const scrollContainer = document.getElementById(
      `header-${this.flavorModGroup.id}`
    );
    const isContainerExpanded =
      document
        .getElementById(`mod-${this.flavorModGroup.id}`)
        ?.classList.contains('show') || false;
    const sticky = (scrollContainer as HTMLElement).offsetTop;
    if (window.pageYOffset >= sticky && isContainerExpanded) {
      scrollContainer?.classList.add('nav-shadow');
    } else {
      scrollContainer?.classList.remove('nav-shadow');
    }
  }

  selectedSpiceHeading() {
    this.flavorModal.openFlavorModal();
  }

  allModifiers() {
    const mergeMap = new Map([
      ...this.limitedGroup,
      ...this.hotGroup,
      ...this.mediumGroup,
      ...this.mildGroup,
    ]);
    return this.mapToArray(mergeMap);
  }

  getKeys(map: Map<string, MenuModifier[]>) {
    return Array.from(map.keys());
  }

  isSelected(id: string): boolean {
    const item =
      this.flavorGroupFormData?.modifiers?.filter((s) => s.modifierId === id)
        ?.length ?? 0;
    return item > 0;
  }

  getSelectedQuantities() {
    return (
      this.flavorGroupFormData?.modifiers?.reduce(
        (a, b) => a + (b['quantity'] || 0),
        0
      ) ?? 0
    );
  }

  getSelectedFlavorNames() {
    const modifiers = this.flavorGroupFormData?.modifiers;
    if (modifiers?.length && modifiers?.length > 0) {
      return modifiers
        ?.map((itm) =>
          itm.quantity > 1
            ? itm.quantity + ' x ' + itm.name?.trimEnd()
            : itm.name?.trimEnd()
        )
        .join(', ');
    }
    return 'None selected';
  }

  /**
   * @ignore
   */
  ngOnDestroy(): void {
    if (!this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  mapToArray(map: Map<string, MenuModifier[]>) {
    const allValues = [...map.values()];
    const arr: MenuModifier[] = [];
    allValues.forEach((val: MenuModifier[]) => {
      // push only one flavor per groupname
      arr.push(val[0]);
    });
    return arr;
  }

  handleFlavorQuantitySelections(values: {
    systemDefined: number;
    userDefined: number;
  }) {
    const { systemDefined, userDefined } = values;
    this.systemDefined = systemDefined;
    this.userDefined = userDefined;
  }

  areRequiredSelectionsDone(): boolean {
    const sumOfAllSelections = this.systemDefined + this.userDefined;
    if (
      this.flavorGroupFormData?.modifiers &&
      this.flavorGroupFormData.modifiers.length >=
        this.flavorModGroup.minSelectionsRequired
    ) {
      if (this.flavorModGroup.quantitiesEnabled) {
        const isValid =
          sumOfAllSelections === this.flavorModGroup.aggregateQuantity;
        this.allSelectionsValid.emit(isValid);
        return isValid;
      }
      // skip above logic to check with aggregateQuantity if quantitiesEnabled: false
      this.allSelectionsValid.emit(true);
      return true;
    }
    this.allSelectionsValid.emit(false);
    return false;
  }

  resetPDPValidations() {
    this.resetPDPValidationsEmit.emit(false);
  }

  public getModifierGroupFromCart(menuModGrpId: string) {
    const hasModifierInCart = find(this.selectedLineItem?.modifierGroups, [
      'modifierGroupId',
      menuModGrpId,
    ]);
    return hasModifierInCart;
  }

  public updateFlavorModifierGroupWithCart(
    flavorModifier: FlavorModifierGroupElement
  ) {
    const modifierFromCart = this.getModifierGroupFromCart(
      this.flavorModGroup.id
    );
    if (modifierFromCart) {
      const hasFlavorModifierInCart = find(modifierFromCart.modifiers, [
        'modifierId',
        flavorModifier.id,
      ]);
      if (hasFlavorModifierInCart) {
        flavorModifier.isDefault = true;
        const flavorModifierIndex = findIndex(modifierFromCart.modifiers, [
          'modifierId',
          flavorModifier.id,
        ]);
        flavorModifier.quantity =
          modifierFromCart.modifiers[flavorModifierIndex].quantity;
      } else {
        flavorModifier.isDefault = false;
        flavorModifier.quantity = 0;
      }
      flavorModifier.isDefault = hasFlavorModifierInCart ? true : false;
    }
    return flavorModifier;
  }

  public updateGroupedFlavorModifierGroupWithCart(
    flavorModifiers: FlavorModifierGroupElement[]
  ) {
    flavorModifiers.forEach((flavorModifier) => {
      this.updateFlavorModifierGroupWithCart(flavorModifier);
    });
    return flavorModifiers;
  }

  private buildFlavorModifiers() {
    this.sortBySortOrder(this.flavorModGroup.modifierGroupElements);
    this.sortByHeatScale(this.flavorModGroup.modifierGroupElements);
    this.sortByIsPopular(this.flavorModGroup.modifierGroupElements);

    this.groupModifiers = new Map(
      Object.entries(this.groupFlavorByGroupName())
    );

    this.groupModifiers.forEach((val: MenuModifier[], key: string) => {
      val.map((el) => {
        if (el.availableInSchedule) {
          if (el.isLimitedTime) {
            this.addToMap(this.limitedGroup, key, el);
          } else {
            const heatScale = this.heatScaleTransform.transform(el);
            if (heatScale > 3) {
              this.addToMap(this.hotGroup, key, el);
            }
            if (heatScale === 2 || heatScale === 3) {
              this.addToMap(this.mediumGroup, key, el);
            }
            if (heatScale < 2) {
              this.addToMap(this.mildGroup, key, el);
            }
          }
        }
      });
    });
  }

  private sortByIsPopular(mod: MenuModifierGroupItem[]) {
    mod.sort(function (x, y) {
      return x.modifier!.isPopular === y.modifier!.isPopular
        ? 0
        : x.modifier!.isPopular
        ? -1
        : 1;
    });
  }

  private sortByHeatScale(el: MenuModifierGroupItem[]) {
    el?.sort((x, y) => {
      return (
        this.heatScaleTransform.transform(y.modifier!) -
        this.heatScaleTransform.transform(x.modifier!)
      );
    });
  }

  private sortBySortOrder(modGroups: MenuModifierGroupItem[]) {
    modGroups?.sort((x, y) => {
      return x.sortOrder - y.sortOrder;
    });
  }

  private groupFlavorByGroupName() {
    return this.flavorModGroup.modifierGroupElements.reduce(function (r, a) {
      let key = a.modifier?.metadata?.['group-name'];
      if (key === undefined) {
        key = a.modifier?.name ?? '';
      }
      r[key] = r[key] || [];
      r[key].push(a.modifier);
      return r;
    }, Object.create(null));
  }

  private addToMap(
    groupType: Map<string, MenuModifier[]>,
    key: string,
    newItem: MenuModifier
  ) {
    const list = groupType?.get(key) ?? [];
    list?.push(newItem);
    groupType.set(key, list);
  }

  /**
   * @ignore
   */
  private createForm(): FormGroup {
    return this.fb.group({
      modifiers: [this.initialValues.modifiers],
    });
  }

  /**
   * @ignore
   */
  private handleFormUpdate(values: FlavorGroupFormData) {
    this.flavorGroupFormData = values;
    this.valuesChanged.emit({
      ...values,
    });
  }
}
